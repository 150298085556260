.alarm-grid {
  padding: 1rem;
}
.alarm-card {
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  /* box-shadow: 0 1px 1px grey; */
  box-shadow: 0 7px 8px -6px grey;
  padding: 1rem;
}

.alarm-title {
  color: #0a4a78;
}
.alarm-code {
  color: #036899;
}
