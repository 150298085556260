@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');

body {
  font-family: 'Muli', sans-serif !important;
  flex-grow: 1;
}

.leaflet-container {
  height: 20vh;
}

 /* For setting background color in app full screen mode */
::backdrop
{
    background-color: #FAFAFA;
}
/* components that are hidden untill in full screen mode */
:fullscreen #fullscreenHeader{
 display: inline;
}
/* components that are hidden in full screen mode */
:fullscreen #hideFullscreenToolbar{
 display: none;
}